$(function(){
  // FAQ一覧ページ
  if (!(Util.namespace_is('front') &&
    Util.controller_is('pages') && Util.action_is('show'))) {
    return false;
  }

  $('ul.faqList.jsList li .ttlBox').on('click', function() {
    // 表示非表示の切替え
    $(this).next().slideToggle();
    // 疑似要素の変更（classのonを付け替え）
    if ($(this).hasClass('on')) {
      $(this).removeClass('on');
    } else {
      $(this).addClass('on');
    }
  })

  // タブの切替え
  const tabs = $('li.faqTab');
  tabs.on('click', function() {
    // タブの切替え
    $('li.on').removeClass('on');
    $(this).addClass('on');
    // 表示内容の切替え
    const targetIndex = tabs.index(this);
    $('ul.faqList.jsList').removeClass('active').eq(targetIndex).addClass('active');
  })
});
