$(function(){
  // 数字のみの入力（出資者情報登録・編集とadminのファンド登録、顧客情報編集ページ）<- 郵便番号、銀行支店番号、口座番号のフォーム
  if (!(Util.namespace_is('front') &&
        (Util.controller_is('step1_registers') || (Util.controller_is('member_details')) &&
          (Util.action_is('edit') || Util.action_is('update'))))
      &&
      !(Util.namespace_is('admin') && (Util.controller_is('funds') &&
        (Util.action_is('new') || Util.action_is('create') ||
          Util.action_is('edit') || Util.action_is('update'))))
      &&
      !(Util.namespace_is('admin') && (Util.controller_is('members') &&
        (Util.action_is('edit') || Util.action_is('update'))))
      ) {
    return false;
  }

  // 全角数字を半角に、半角数字以外の文字は空文字にして出力
  $('.js-input-number').on('input', function(e) {
    let value = $(e.currentTarget).val();
    value = value.replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .replace(/[^0-9]/g, '');
    $(e.currentTarget).val(value);
  });
});
