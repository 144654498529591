$(function () {
  // 画像プレビュー（出資者情報登録・編集）
  if (!(Util.namespace_is('front') &&
    (Util.controller_is('step1_registers') || (Util.controller_is('member_details')) &&
      (Util.action_is('edit') || Util.action_is('update'))))) {
    return false;
  }

  const buildImg = (url) => {
    const html = `<img width='200' src=${url}>`;
    return html;
  }

  $('.imageForm').on('change', function(e){
    $(this).closest('dd').find('img').remove();
    const blobUrl = window.URL.createObjectURL(e.target.files[0]);
    $(this).closest('dd').find('.image-preview').append(buildImg(blobUrl));
  })
});
