$(function(){
  // ファンド申込ページ
  if (!(Util.namespace_is('front') &&
    Util.controller_is('funds') && Util.action_is('show'))) {
    return false;
  }
  // PDF確認済みか、口数の入力値が問題ないか判定して、ボタンを押せるようにする関数
  function enableClickBtn () {
    let lot = $('select#tmpLotForm').val();
    if (isNaN(lot)) {
      return
    }
    if (
      $('input#checkPdf1').prop('checked') === true &&
      $('input#checkPdf2').prop('checked') === true &&
      lot > 0 && lot <= 100
    ) {
      $('input#applyBtn').removeClass('disabled');
    } else {
      $('input#applyBtn').addClass('disabled');
    }
  }

  // リンククリック時にイベント発火
  $('a#pdf1Link').on('click', function() {
    $('input#checkPdf1').prop("checked", true);
    enableClickBtn();
  })
  $('a#pdf2Link').on('click', function() {
    $('input#checkPdf2').prop("checked", true);
    enableClickBtn();
  })

  let appendInvestmentAmount = (lot) => {
    const investmentAmount = Math.ceil(lot * 10_000).toLocaleString();
    const amountElement = $('#investmentAmount');
    amountElement.empty();
    amountElement.append(investmentAmount);
    enableClickBtn();
    // モーダルの中身も書き換えておく
    const modalLot = $('span.rTxt#modalLot');
    const modalInvestAmount = $('span.rTxt#modalInvestAmount');
    modalLot.empty();
    modalInvestAmount.empty();
    modalLot.append(`${lot}口`);
    modalInvestAmount.append(`${investmentAmount}円`);
  }

  // 初期化
  appendInvestmentAmount($('#tmpLotForm').val())
  // 金額の自動計算と応募ボタン押せるかの判定
  $('#tmpLotForm').on('change', function() {
    let lot = $(this).val();
    appendInvestmentAmount(lot)
  })

  // 応募時のモーダル
  $('#applyBtn').on("click", function(e) {
    e.preventDefault();
    $('.pop01').fadeIn();
    $('#cancelBtn').on('click', function() {
      $('.pop01').fadeOut();
    })
  })

  // enter keyでsubmitできないようにする（PDFのリンクをクリックさせるため）
  $("input").on("keydown", function(e) {
    const enterKeyCode = 13
    return !((e.which && e.which === enterKeyCode) || (e.keyCode && e.keyCode === enterKeyCode));
  });
})
