import zipcodeJa from 'zipcode-ja';

// 郵便番号から自動で住所入力
$(document).ready(function(){
  $('.js-zip-code').blur(function() {
    const zipcodeja = zipcodeJa[$(this).val()];
    if (typeof zipcodeja === 'undefined') {
      return false;
    }
    const pref = zipcodeja.address.shift();
    const address = zipcodeja.address.join('');
    const pref_id = $('select.js-pref option').filter(function() {
      return $(this).html() === pref;
    }).val();
    $('select.js-pref').val(pref_id);
    $('input.js-address').val(address);
  });

  // 市区町村とそれ以降が分かれているフォーム用
  $('.js-zip-code2').blur(function() {
    const zipcodeja = zipcodeJa[$(this).val()];
    if (typeof zipcodeja === 'undefined') {
      return false;
    }
    const pref = zipcodeja.address.shift();
    const address1 = zipcodeja.address[0];
    const address2 = zipcodeja.address[1];
    const pref_id = $('select.js-pref2 option').filter(function() {
      return $(this).html() === pref;
    }).val();
    $('select.js-pref2').val(pref_id);
    $('input.js-address1').val(address1);
    $('input.js-address2').val(address2);
  });
});
