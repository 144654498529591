$(function(){
  // 当選後マイページから応募を確定するときのPDF閲覧チェック
  if (!(Util.namespace_is('front') &&
    Util.controller_is('applications') && Util.action_is('index'))) {
    return false;
  }

  // 対象のチェックボックスがチェック済みか判定して、ボタンを押せるようにする関数
  function enableClickBtn (id) {
    if (
      $(`input#application-${id}-check01`).prop('checked') === true &&
      $(`input#application-${id}-check02`).prop('checked') === true
    ) {
      $(`#confirmBtn-${id}`).removeClass('disabled');
    }
  }
  // pdf3とpdf4のリンクを取得しておく
  const pdf3Lists = $('.linkTxt.pdf3Link.pdfList');
  const pdf4Lists = $('.linkTxt.pdf4Link.pdfList');

  // リンククリック時にイベント発火
  pdf3Lists.on('click', function() {
    const targetId = $(this).data('applicationId');
    const targetCheckBox = $(`input#application-${targetId}-check01`)
    targetCheckBox.prop("checked", true);
    enableClickBtn(targetId);
  })
  pdf4Lists.on('click', function() {
    const targetId = $(this).data('applicationId');
    const targetCheckBox = $(`input#application-${targetId}-check02`)
    targetCheckBox.prop("checked", true);
    enableClickBtn(targetId);
  })
})
