$(function(){
  $('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      let $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
      if ($target.length) {
        if($(this).parents('.menuBox').length){
          setTimeout(function(){
            let targetOffset = $target.offset().top;
            $('html,body').animate({scrollTop: targetOffset}, 1000);
          },100);
        }else{
          let targetOffset = $target.offset().top;
          $('html,body').animate({scrollTop: targetOffset}, 1000);
        }
        return false;
      }
    }
  });
});

$(window).on('load',function(){
  let localLink = window.location+'';
  if(localLink.indexOf("#") != -1 && localLink.slice(-1) != '#'){
    localLink = localLink.slice(localLink.indexOf("#")+1);
    $('html,body').animate({scrollTop: $('#'+localLink).offset().top}, 500);
  }
});

