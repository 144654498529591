$(function(){
  const menuBox = $('.menuBox')
  $('.menu.sp').on('click', function(){
    if (menuBox.css('display') === 'none') {
      menuBox.fadeIn();
    } else {
      menuBox.fadeOut();
    }
  })
});
