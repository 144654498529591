$(function(){
  if (!(Util.namespace_is('front') &&
    Util.controller_is('step3_registers') && Util.action_is('show'))) {
    return false
  }

  const liquidButton = document.querySelector('a#jsLiquidButton')
  liquidButton.addEventListener('click', handleLiquidRequest)

  LIQUIDEKYC.API_ENDPOINT = liquidButton.dataset.apiEndpoint
  LIQUIDEKYC.KEY = liquidButton.dataset.apiKey

  const idDocumentTypes = ['01', '02', '03'] // 運転免許証, マイナンバーカード, 在留カード
  const liquidParams = JSON.parse(liquidButton.dataset.liquidParams)
  const params = { id_document_types: idDocumentTypes, ...liquidParams }

  async function handleLiquidRequest(e) {
    e.preventDefault()
    try {
      const { application_url } = await LIQUIDEKYC.startKYC(params)
      location.href = application_url
    } catch(e) {
      // すでに提出済みの場合などもエラーになる
      window.alert('問題が発生しました')
    }
  }
})
