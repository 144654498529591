$(function(){
  // ファンド詳細ページ
  if (!(Util.namespace_is('front') &&
    Util.controller_is('funds') && Util.action_is('show'))) {
    return false;
  }

  // タブの切替え
  const lists = $('.comNaviBox li');
  lists.on('click', function() {
    $('li.on').removeClass('on');
    $(this).addClass('on');
    // 表示内容の切替え
    const index = lists.index(this) + 1;
    const targetTab = $(`.tab${index}`)
    $('.tabContent').removeClass('active');
    targetTab.addClass('active');
  })
});

// slickの設定
$(document).ready(function(){
  // navバーの表示は最大5枚
  let slidesToShow = 5;
  const ImageCount = $('ul.navList.slider-nav li').length;
  if (ImageCount < slidesToShow){
    slidesToShow = ImageCount;
  }
  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  });
  $('.slider-nav').slick({
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: '10%'
  });
});
