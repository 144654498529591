$(function(){
  // 新規登録ページ（メールアドレス登録とSNS登録）
  if (!(Util.namespace_is('front') &&
    (Util.controller_is('members') || (Util.controller_is('sns_registrations')) &&
    (Util.action_is('new') || Util.action_is('create'))))) {
    return false;
  }

  // 同意が必要なチェックリストを取得
  const checkLists = $('.agreeChecks');

  // チェックボックスが変更されたタイミングでチェックされてない要素を取得し、disabledの判定と付替え
  checkLists.change(function(){
    const notCheckedBoxes = checkLists.find('input:checkbox:not(:checked)');
    if (notCheckedBoxes.length === 0) {
      $('input#needAgreeBtn').removeClass('disabled');
    } else {
      $('input#needAgreeBtn').addClass('disabled');
    }
  })
})
