$(function(){
  // お問い合わせページ
  if (!(Util.namespace_is('front') &&
    Util.controller_is('contacts') &&
      (Util.action_is('new') || Util.action_is('create')))) {
    return false;
  }

  const successCallback = () => {
    $('#submit-btn').prop('disabled', false);
  }
  window.successCallback = successCallback;
});
